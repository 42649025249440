import React, { useState, Fragment } from "react";
import { Form, Field } from "react-final-form";
import TextField from "./TextField";
import Button from "./Button";
import Official from "./Official";
import findReps from "../utils/findReps";
import { useRecoilState, useSetRecoilState } from "recoil";
import { selectedRepsState } from "../recoil/selectedRepsState";
import { zipCodeState } from "../recoil/zipCodeState";
import { TOfficial } from "../definitions/official";

interface IProps {
  visible: boolean;
  onShow?: () => void;
  onHide?: () => void;
}

export default function FindReps({ visible, onHide }: IProps) {
  const [officials, setOfficials] = useState([]);
  const [emptyResponse, setEmptyResponse] = useState(false);
  const [councilMembers, setCouncilMembers] = useState<TOfficial[]>([]);
  const [selectedReps, setSelectedReps] = useRecoilState(selectedRepsState);
  const setZipCode = useSetRecoilState(zipCodeState);

  const addRep = (rep: TOfficial) => {
    if (isCouncilMember(rep)) {
      setCouncilMembers([...councilMembers, rep]);
      setSelectedReps([...selectedReps, rep] as any);
    }
  };

  const removeRep = (rep: TOfficial) => {
    if (isCouncilMember(rep)) {
      setCouncilMembers(selectedReps.filter((r) => r !== rep));
      setSelectedReps(selectedReps.filter((r) => r !== rep));
    }
  };

  const handleRepChange = (rep: TOfficial) => {
    if (selectedReps.includes(rep as never)) {
      removeRep(rep);
    } else {
      addRep(rep);
    }
  };

  const isMayoral = (rep: TOfficial): boolean => {
    return ["John Cooper", "Jim Shulman"].includes(rep.name);
  };

  const isCouncilMember = (rep: TOfficial): boolean => {
    return rep.office.name === "Nashville-Davidson County Metro Council Member";
  };

  const isSelected = (rep: TOfficial) => {
    // Always have the mayor and vice mayor selected
    if (isMayoral(rep)) {
      return true;
    }

    return selectedReps.includes(rep as never);
  };

  const onSubmit = async (values: any) => {
    const response = await findReps(values.address.replace("#", ""));

    const result = await response;

    if (result && result.officials.length > 0) {
      setEmptyResponse(false);
      setOfficials(result.officials);
    } else {
      setEmptyResponse(true);
      setOfficials([]);
    }

    result.officials.forEach((off: TOfficial) => {
      if (isMayoral(off)) {
        addRep(off);
      }
    });

    setZipCode(result.normalizedInput.zip);

    return result;
  };

  // const councilMembers: TOfficial[] =
  //   selectedReps.find(
  //     (off: TOfficial) =>
  //       off.office.name === "Nashville-Davidson County Metro Council Member"
  //   ) || [];

  const emailButtonText = () => {
    if (councilMembers.length > 0) {
      return `Customize your email to ${
        councilMembers.length || ""
      } Council Member${
        councilMembers.length === 1 ? "" : "s"
      }, the Vice Mayor, and the Mayor`;
    } else {
      return "Email the Vice Mayor and the Mayor";
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, invalid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-wrap mt-10 md:px-24 md:-mx-6">
                <div className="w-full md:w-1/2 mr-auto ml-auto px-3 md:px-6">
                  <div className="mb-8">
                    <p>
                      Nashville’s Metro Council will vote on next year’s budget
                      TONIGHT, Tuesday (6/16) at 6:30 p.m. Here is what you can
                      do to help Council pass a budget that defends Black lives
                      by divesting from policing and cages and investing in our
                      communities!
                    </p>
                    <br />
                    <p>
                      To prepare, take 3 minutes to educate yourself! Head to
                      our main site,{" "}
                      <a
                        href="https://nashvillepeoplesbudget.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-700"
                      >
                        https://nashvillepeoplesbudget.org/
                      </a>
                      , to read about the budgets (CM Mendes) and amendments (CM
                      Welsch and others) that begin to respond to our call for a
                      budget that divests from policing and jails and invests in
                      our communities.
                    </p>
                    <br />
                    <p>
                      Use this tool to find YOUR Council members and email and
                      call them by 2:00 p.m. today, Tuesday, June 16!
                    </p>
                  </div>
                  <Field
                    name="address"
                    render={({ input }) => (
                      <TextField
                        label="Your address"
                        placeholder="1 Public Sq #204, Nashville 37201"
                        autoFocus
                        helpText="Street, City ZIP code"
                        {...input}
                      />
                    )}
                  />
                  <Button
                    size="xl"
                    fullWidth
                    type="submit"
                    className="mb-4"
                    disabled={pristine}
                  >
                    Find My Council Members
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      />

      <div className="flex flex-wrap md:px-24 md:-mx-6">
        <div className="w-full md:w-1/2 mr-auto ml-auto px-3 md:px-6">
          {emptyResponse && (
            <div className="mt-8">
              No council members found. Please try a different address.
            </div>
          )}
          {!emptyResponse && officials.length > 0 && (
            <Fragment>
              <div className="border border-solid border-gray-200 my-6" />
            </Fragment>
          )}
          {selectedReps.length > 0 && (
            <Button onClick={onHide} className="mb-6 p-4" fullWidth size="xl">
              {emailButtonText()}
            </Button>
          )}
          {!emptyResponse && officials.length > 0 && (
            <Fragment>
              <div className="my-6">
                Click/tap the Metro Council members below who you would like to
                email as well.
              </div>
              <div className="mb-6">
                In the next step, we will automatically create an email draft
                for you to send to Mayor John Cooper, Vice Mayor Jim Shulman,
                and your selected Council members.
              </div>
            </Fragment>
          )}
          {officials.map((off: any, idx) => {
            return (
              <Official
                key={idx}
                name={off.name}
                emails={off.emails}
                office={off.office}
                photo={off.photoUrl}
                onClick={() => handleRepChange(off)}
                className={`${
                  isSelected(off)
                    ? "shadow-lg border-2 border-solid border-green-500"
                    : ""
                } cursor-pointer`}
              />
            );
          })}
        </div>
      </div>
    </Fragment>
  );
}
