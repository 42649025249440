import React, { Fragment, ChangeEvent } from "react";

interface IProps {
  name: string;
  label: string;
  labelHidden?: boolean;
  helpText?: string;
  placeholder?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
}

export default function TextField({
  name,
  label,
  labelHidden = false,
  helpText,
  placeholder,
  autoFocus = false,
  onChange,
  ...rest
}: IProps) {
  return (
    <Fragment>
      <label
        htmlFor={name}
        className={`block text-lg font-medium leading-5 text-gray-800 mb-4 ${
          labelHidden ? "sr-only" : ""
        }`}
      >
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm w-full">
        <input
          id={name}
          name={name}
          className="form-input block w-full sm:text-sm sm:leading-5 p-2 rounded"
          placeholder={placeholder}
          // type="text"
          autoFocus={autoFocus}
          // {...rest}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChange && onChange(event.target.value);
          }}
          // aria-describedby="email-description"
        />
      </div>
      {helpText && <p className="mt-2 text-sm text-gray-500">{helpText}</p>}
      <div className="mb-4" />
    </Fragment>
  );
}
