import React, { useState } from "react";
import "./styles/main.css";
import { RecoilRoot } from "recoil";
import FindReps from "./components/FindReps";
import logo from "./assets/images/npbc-logo.png";
import LastCall from "./components/LastCall";

export default function App() {
  const [showFindReps, setShowFindReps] = useState(true);

  return (
    <RecoilRoot>
      <main
        className="h-screen"
        style={{ backgroundColor: "hsla(0, 0%, 98%, 1)" }}
      >
        <div className="mt-20 flex justify-center items-center">
          {/* <h1 className="text-4xl leading-6 font-medium text-gray-900">
            Email Your Reps
          </h1> */}
          <img src={logo} className="w-32 h-32" alt="" />
        </div>
        <div className="mt-6 flex justify-center items-center">
          <h3 className="text-xl leading-6 font-medium text-gray-900">
            Change Nashville's Budget
          </h3>
        </div>
        <FindReps
          visible={showFindReps}
          onHide={() => setShowFindReps(false)}
        />
        {/* <EmailForm
          visible={!showFindReps}
          onHide={() => setShowFindReps(true)}
        /> */}
        <LastCall
          visible={!showFindReps}
          onHide={() => setShowFindReps(true)}
        />
      </main>
    </RecoilRoot>
  );
}
