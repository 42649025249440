import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import TextField from "./TextField";
import copyToClipboard from "../utils/copyToClipboard";
import { useRecoilValue } from "recoil";
import { zipCodeState } from "../recoil/zipCodeState";
import { selectedRepsState } from "../recoil/selectedRepsState";
import { TOfficial } from "../definitions/official";
import Card from "./Card";

interface IProps {
  visible: boolean;
  onHide?: () => void;
}

export default function LastCall({ visible = false, onHide }: IProps) {
  const [name, setName] = useState<string>("");
  const zipCode = useRecoilValue(zipCodeState);
  const selectedReps = useRecoilValue(selectedRepsState);
  const [content, setContent] = useState("");
  const [copyContent, setCopyContent] = useState("");
  const [copiedSnippet, setCopiedSnippet] = useState(false);
  const [copiedTo, setCopiedTo] = useState(false);

  useEffect(() => {
    setContent(
      `Metro Council Members,
      %0D%0A%0D%0AMy name is ${name} and I am a resident of ${
        zipCode || "Nashville"
      }.
      %0D%0A%0D%0ADo you support defunding police and incarceration and investing in our communities?`
    );
  }, [name, zipCode]);

  useEffect(() => {
    setCopyContent(
      `Metro Council Members,
      \n\nMy name is ${name} and I am a resident of ${zipCode || "Nashville"}.
      \n\nDo you support defunding police and incarceration and investing in our communities?`
    );
  }, [name, zipCode]);

  useEffect(() => {
    if (copiedSnippet) {
      setTimeout(() => {
        setCopiedSnippet(false);
      }, 5_000);
    }
  }, [copiedSnippet]);

  useEffect(() => {
    if (copiedTo) {
      setTimeout(() => {
        setCopiedTo(false);
      }, 5_000);
    }
  }, [copiedTo]);

  function handleCopyToClipBoard() {
    setCopiedSnippet(true);
    copyToClipboard(copyContent);
  }

  function handleCopyAddressesToClipBoard() {
    setCopiedTo(true);
    copyToClipboard(
      selectedRepEmails +
        ",jim.shulman@nashville.gov,mayor@nashville.gov,councilmembers@nashville.gov"
    );
  }

  const copyAddressesToButton = (
    <button
      type="button"
      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-green-700 bg-green-100 hover:bg-green-50 focus:outline-none focus:border-green-300 focus:shadow-outline-green active:bg-green-200 transition ease-in-out duration-150"
      onClick={() => handleCopyAddressesToClipBoard()}
    >
      {copiedTo ? "Copied!" : "Copy"}
    </button>
  );

  const copyContentButton = (
    <button
      type="button"
      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-green-700 bg-green-100 hover:bg-green-50 focus:outline-none focus:border-green-300 focus:shadow-outline-green active:bg-green-200 transition ease-in-out duration-150"
      onClick={() => handleCopyToClipBoard()}
    >
      {copiedSnippet ? "Copied!" : "Copy"}
    </button>
  );

  const selectedRepEmails = selectedReps
    .map((rep: TOfficial) => rep.emails)
    .join(",");

  const displaySelectedRepEmails = selectedReps
    .map((rep: TOfficial) => rep.emails)
    .join(", ");

  const emailToString = `mailto:${selectedRepEmails},jim.shulman@nashville.gov,mayor@nashville.gov,councilmembers@nashville.gov?subject=Do you support defunding the police?&body=${content}`;

  if (!visible) {
    return null;
  }

  return (
    <Form
      onSubmit={() => undefined}
      render={({ handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap mt-20 md:px-24 md:-mx-6">
              {/* <div className="w-full md:w-1/4 px-3 md:px-6"> */}
              {/* <Field
                  name="name"
                  type="text"
                  render={({ input, meta }) => (
                    <TextField
                      name="name"
                      label="Your first and last names"
                      autoFocus
                      onChange={setName(input.value)}
                      {...input}
                    />
                  )}
                /> */}
              {/* <div className="h-4" />
                <Field
                  name="zip"
                  type="text"
                  render={({ input, meta }) => (
                    <TextField
                      name="zip"
                      label="Your ZIP code"
                      onChange={setZip(input.value)}
                      {...input}
                    />
                  )}
                /> */}
              {/* </div> */}
              <div className="w-full md:w-2/3 ml-auto mr-auto px-3 md:px-6">
                <Field
                  name="name"
                  type="text"
                  render={({ input, meta }) => (
                    <TextField
                      label="Your first and last names"
                      autoFocus
                      {...input}
                      onChange={(val: string) => setName(val)}
                    />
                  )}
                />
                <Card className="mb-6">
                  <div className="flex flex-wrap items-center justify-between">
                    <div className="break-all">
                      To:{" "}
                      {displaySelectedRepEmails +
                        ", jim.shulman@nashville.gov, mayor@nashville.gov, councilmembers@nashville.gov"}
                    </div>
                  </div>
                  <div className="mt-2 flex justify-end items-center">
                    {copyAddressesToButton}
                  </div>
                </Card>
                <div
                  className="bg-white overflow-hidden shadow rounded-lg"
                  onClick={() => handleCopyToClipBoard()}
                >
                  <div className="p-6">
                    <EmailContent name={name} zip={zipCode} />
                    <div className="flex justify-end items-center">
                      {copyContentButton}
                    </div>
                  </div>
                </div>
                {/* <div className="mt-4">{copyContentButton()}</div> */}
                <div className="mt-4">
                  <a
                    href={emailToString}
                    className={`${
                      !name &&
                      "opacity-50 cursor-not-allowed pointer-events-none"
                    } inline-flex w-full justify-center items-center px-6 py-4 border border-transparent text-lg leading-4 font-medium rounded text-green-100 bg-green-700 hover:bg-green-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green active:bg-green-400 transition ease-in-out duration-150`}
                  >
                    Send this to {selectedReps.length + 2} representatives
                  </a>
                </div>
                <div className="mt-6">
                  <div className="text-lg">
                    Please share your council member’s response with us. Do not
                    include your council member's personal information if it is
                    not already public.
                  </div>
                  <div className="mt-4 text-lg">
                    Join us for a rally TONIGHT, Tuesday (6/16) at 5:00 p.m. at
                    City Hall. We will demand that council members vote to
                    defend Black lives by defunding police and cages, and
                    investing in our communities! Metro Council’s meeting will
                    begin at 6:30 p.m. Wear your mask. We will rally safely.
                  </div>
                </div>
                <div className="mt-6">
                  <div className="text-lg">
                    Your voice is your own and we would never take that away
                    from you. Clicking the button above will create this email
                    as a draft in your email application. You will be able to
                    customize it as you see fit and send it when you are ready.
                  </div>
                  <div className="mt-4 text-lg">
                    Thank you for supporting a people's budgeting process.
                  </div>
                  <div className="mt-4 mb-6 text-lg">
                    All power to you. Follow us @NashvillePeoplesBudget for more
                    information about a participatory budgeting process.
                  </div>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    />
  );
}

interface IEmailContentProps {
  name: string;
  zip: string;
}

function EmailContent({ name, zip }: IEmailContentProps) {
  return (
    <div>
      <p>Metro Council Members,</p>
      <br />
      <p>
        My name is{" "}
        <span className="p-1 bg-gray-100">{name || "Your name"}</span> and I am
        a resident of{" "}
        <span className="p-1 bg-gray-100">{zip || "Nashville"}</span>.
      </p>
      <br />
      <p>
        Do you support defunding police and incarceration and investing in our
        communities?
      </p>
    </div>
  );
}
