import React from "react";
import { TOfficial } from "../definitions/official";
import Card from "./Card";
import split from "lodash/split";

interface IProps {
  onClick?: () => void;
  className?: string;
}

type TInteractiveOfficial = TOfficial & IProps;

export default function Official({
  name,
  emails,
  office,
  photo,
  onClick,
  className,
}: TInteractiveOfficial) {
  const initials = `${split(name, " ")[0][0]}${split(name, " ")[1][0]}`;

  const emailsMarkup =
    emails &&
    emails.map((em) => {
      return (
        <div key={em} className="text-lg">
          {em}
        </div>
      );
    });

  const photoMarkup = photo ? (
    <img
      className="inline-block h-20 w-20 rounded-lg object-cover"
      src={photo}
      alt={`${name} - ${office?.name}`}
    />
  ) : (
    <span className="inline-block h-20 w-20 rounded-lg overflow-hidden bg-gray-100 flex justify-center items-center">
      <span>{initials}</span>
      {/* <svg
        className="h-full w-full text-gray-300"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg> */}
    </span>
  );

  return (
    <div onClick={onClick}>
      <Card className={`${className} mb-4 mx-1`}>
        <div className="flex">
          <div className="flex-2 mr-4">{photoMarkup}</div>
          <div className="flex-4">
            <div className="text-lg">{name}</div>
            <div className="text-lg">{office?.name}</div>
            {emailsMarkup}
          </div>
        </div>
      </Card>
    </div>
  );
}
