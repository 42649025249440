import React, { ReactNode } from "react";

type TButtonVersion = "primary" | "secondary" | "plain";

interface IProps {
  children: ReactNode;
  version?: TButtonVersion;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  fullWidth?: boolean;
  className?: string;
}

type TButtonsProps = IProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button(props: TButtonsProps) {
  const {
    children,
    version = "primary",
    size = "md",
    fullWidth = false,
    className,
    ...restProps
  } = props;

  let calculatedClassNames = "";

  switch (version) {
    case "primary":
      calculatedClassNames = calculatedClassNames.concat(
        " bg-green-600 text-white hover:bg-green-500 focus:border-green-700 focus:shadow-outline-green active:bg-green-700 "
      );
      break;
    case "secondary":
      calculatedClassNames = calculatedClassNames.concat(
        " bg-green-100 text-green-700 bg-green-100 hover:bg-green-50 focus:border-green-300 focus:shadow-outline-green active:bg-green-200"
      );
      break;
    case "plain":
      calculatedClassNames = calculatedClassNames.concat(
        " border border-gray-300 text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 "
      );
      break;
    default:
      break;
  }

  if (restProps.disabled) {
    calculatedClassNames = calculatedClassNames.concat(
      " opacity-50 cursor-not-allowed "
    );
  }

  if (fullWidth) {
    calculatedClassNames = calculatedClassNames.concat(" w-full text-center ");
  }

  switch (size) {
    case "xs":
      calculatedClassNames = calculatedClassNames.concat(
        " text-xs px-2.5 py-1.5 leading-4 rounded"
      );
      break;
    case "sm":
      calculatedClassNames = calculatedClassNames.concat(
        " text-sm px-3 py-2 leading-4 rounded-md"
      );
      break;
    case "md":
      calculatedClassNames = calculatedClassNames.concat(
        " text-sm px-4 py-2 leading-5 rounded-md"
      );
      break;
    case "lg":
      calculatedClassNames = calculatedClassNames.concat(
        " text-sm px-4 py-2 leading-6 rounded-md"
      );
      break;
    case "xl":
      calculatedClassNames = calculatedClassNames.concat(
        " text-base px-6 py-3 leading-6 rounded-md"
      );
      break;
    default:
      break;
  }

  return (
    <button
      {...restProps}
      className={`${calculatedClassNames} font-medium inline-flex justify-center items-center border border-transparent focus:outline-none transition ease-in-out duration-150 ${className}`}
    >
      {children}
    </button>
  );
}
