const findReps = async (address: string) => {
  const response = await fetch(
    `https://www.googleapis.com/civicinfo/v2/representatives?address=${address}N&includeOffices=true&levels=administrativeArea2&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  const result = await response.json();

  if (result?.error?.errors?.length > 0) {
    return null;
  }

  let offices = result.offices;
  let officials = result.officials;

  offices.map(
    (office: string, idx: number) => (officials[idx].office = office)
  );

  officials = officials.map((off: any) => {
    if (off.name === "Howard Gentry") {
      off.office = { name: "Nashville-Davidson County Criminal Court Clerk" };
    } else if (
      ["Jim Shulman", "John Cooper", "Richard R. Rooker"].includes(off.name)
    ) {
      off.office = { name: "" };
    } else if (off.office === undefined) {
      off.office = { name: "Nashville-Davidson County Metro Council Member" };
    } else if (
      off.office.name === "Nashville-Davidson County Juvenile Court Clerk"
    ) {
      off.office.name = "Nashville-Davidson County Metro Council Member";
    } else if (
      off.office.name === "Nashville-Davidson County Assessor of Property"
    ) {
      off.office.name = "Nashville-Davidson County Metro Council Member";
    }
    return off;
  });

  result.officials = officials.filter((off: any) =>
    [
      "Nashville-Davidson County Metro Council Member",
      // "Nashville-Davidson County Metro Mayor",
      // "Nashville-Davidson County Vice Mayor",
      undefined,
    ].includes(off.office?.name)
  );

  return result;
};

export default findReps;
